import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useSelector } from 'react-redux';
// Helpers
import sanitizeHtml from 'sanitize-html';

import BadgeMembership from 'ui-kit/bagde/badge-membership';
import Button from 'ui-kit/button/button';
import LoadingMessage from 'ui-kit/loading-message/loading-message';

import AlertMessages from 'display-components/membership/alert-messages';

// Components and UI kit
import MembershipManagementBadgeStatus from 'components/membership-management-badge/membership-management-badge.component';

// States
import { accountProfilEPostPatientNumSelector } from 'state/account/account.selectors';
import { definePlanStatus, MembershipPlanStatusEnum } from 'state/membership/membership.helpers';
import {
    membershipInviteeMembersSelector,
    membershipIsCarePlanSelector,
    membershipIsLoadingDetailsSelector
} from 'state/membership/membership.selector';

// Types
import { MembershipDetails } from 'types/membership';

// Styles
import './card-your-plans.style.scss';

export interface CardYourPlansProps extends MembershipDetails {
    onUpgradePlan?: () => void;
    onMakePayment?: () => void;
    isInvitee?: boolean;
    isTelemedicine?: boolean;
}

const CardYourPlans: React.FC<CardYourPlansProps> = (props) => {
    const {
        planName,
        planStatus,
        nextPaymentAmount,
        nextPaymentDate,
        lastPaymentAmount,
        lastPaymentDate,
        memberId,
        memberSinceDate,
        isInvitee,
        isTelemedicine,
        isPlanUpgradable,
        onMakePayment,
        onUpgradePlan
    } = props;
    const isLoading = useSelector(membershipIsLoadingDetailsSelector);
    const members = useSelector(membershipInviteeMembersSelector);
    const profileEPostNum = useSelector(accountProfilEPostPatientNumSelector);
    const isCarePlan = useSelector(membershipIsCarePlanSelector);

    const { t } = useTranslation();

    const isPendingPlan = ['ONHOLD', 'PAYMENT_DUE'].includes(planStatus);
    // DRX-2620: Hidden section for the future releases
    // const isActivePlan = planStatus === MembershipPlanStatusEnum.Active;
    const isCancelledPlan = planStatus === MembershipPlanStatusEnum.Canceled;
    const hasMessageBlurb = isPendingPlan || isTelemedicine;

    const inviteeSinceDate = isInvitee
        ? members?.find((f) => f.epostPatientNum === profileEPostNum)?.dateGranted
        : undefined;

    const paymentText = t('components.cardYourPlan.nextPayment', {
        nextPaymentDate,
        nextPaymentAmount
    });

    const paymentAttemptText = t('components.cardYourPlan.nextAttemptPayment', {
        nextPaymentDate,
        nextPaymentAmount
    });

    const paymentCancelledText = t('components.cardYourPlan.subscriptionCanceled', {
        canceledAt: nextPaymentDate
    });

    const nextPaymentText = (status: CardYourPlansProps['planStatus']): string => {
        switch (status) {
            case MembershipPlanStatusEnum.OnHold:
                return paymentAttemptText;
            case MembershipPlanStatusEnum.PaymentDue:
                return paymentAttemptText;
            case MembershipPlanStatusEnum.Canceled:
                return paymentCancelledText;
            default:
                return paymentText;
        }
    };

    if (isLoading) {
        return (
            <div className="card-your-plan-container">
                <LoadingMessage text={t(`components.cardYourPlan.loading`)} isVisible={isLoading} />
            </div>
        );
    }

    return (
        <div className="card-your-plan-container">
            <div className="card-your-plan-container__box">
                <div className="card-your-plan-container__details">
                    <h3 className="card-your-plan-container__title">{t('components.cardYourPlan.yourPlan')}</h3>
                    <div className="card-your-plan-container__heading">
                        <BadgeMembership variant={planName.toLowerCase()} />
                        {!isTelemedicine && (
                            <MembershipManagementBadgeStatus
                                planStatus={planStatus}
                                formattedPlanStatus={definePlanStatus(planStatus)}
                            />
                        )}
                    </div>
                    {!isInvitee && !isTelemedicine && (
                        <div>
                            <div className="d-flex flex-column">
                                {nextPaymentDate && nextPaymentDate && !isCancelledPlan && !isPendingPlan && (
                                    <p
                                        className="card-your-plan-container__next-payment"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                t('components.cardYourPlan.nextPayment', {
                                                    nextPaymentDate: nextPaymentDate,
                                                    nextPaymentAmount: nextPaymentAmount
                                                })
                                            )
                                        }}
                                    />
                                )}
                                {lastPaymentDate && lastPaymentAmount && !isCancelledPlan && !isPendingPlan && (
                                    <p className="card-your-plan-container__last-payment">
                                        {t('components.cardYourPlan.lastPayment', {
                                            lastPaymentDate: lastPaymentDate,
                                            lastPaymentAmount: lastPaymentAmount
                                        })}
                                    </p>
                                )}
                                {isCancelledPlan && (
                                    <p className="card-your-plan-container__last-payment">
                                        {t('components.cardYourPlan.lastPaymentCanceled', {
                                            lastPaymentDate: lastPaymentDate
                                        })}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {/* DRX-2917: Adjusted validation order to match both care and on demand plans */}
                {!isInvitee && (
                    <>
                        {!isPendingPlan && !isCancelledPlan && isPlanUpgradable && (
                            <Button
                                type="button"
                                variant={isCarePlan ? 'outline-primary' : 'primary'}
                                className="btn-bold btn-outline btn-upgrade"
                                onClick={onUpgradePlan}
                                label={t('components.cardYourPlan.upgradePlan')}
                            />
                        )}
                    </>
                )}
                {!isInvitee && !isTelemedicine && (
                    <>
                        {isPendingPlan && !isCancelledPlan && (
                            <Button
                                type="button"
                                variant="outline-primary"
                                className="btn-bold btn-outline"
                                onClick={onMakePayment}
                                label={t('components.cardYourPlan.makePayment')}
                            />
                        )}
                    </>
                )}
                {hasMessageBlurb && !isTelemedicine && (
                    <div className="card-your-plan-container__blurb">
                        <AlertMessages
                            planStatus={planStatus}
                            isInvitee={isInvitee || false}
                            isTelemedicine={isTelemedicine || false}
                            nextPaymentText={nextPaymentText(planStatus)}
                        />
                    </div>
                )}
            </div>
            <div className="card-your-plan-container__footer">
                <div className="card">
                    <h3 className="title">{t('components.cardYourPlan.memberId')}</h3>
                    <p className="text">{memberId}</p>
                </div>
                <div className="card">
                    <h3 className="title">{t('components.cardYourPlan.memberSince')}</h3>
                    <p className="text">{inviteeSinceDate || memberSinceDate}</p>
                </div>
            </div>
        </div>
    );
};

export default CardYourPlans;
