import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import ContactInfoBox from 'display-components/contact-info-box';
import 'display-components/membership/alert-messages/alert-messages.styles.scss';
import MembershipMemberDetails from 'display-components/membership/member-details';

import { membershipIsLoadingSendInviteSelector } from 'state/membership/membership.selector';

import { MembershipInvitationMethods } from 'types/membership';

import { lowercaseAndCapitalize } from 'util/cart';

import './membership-send-invitation.styles.scss';

export type SendInvitationMethods = 'Email' | 'Sms';
export interface MemberToAdd {
    firstName: string;
    lastName: string;
    age: string;
    dateOfBirth: string;
    accountType: number;
    email: string;
    phone: string;
}

interface MembershipSendInvitationProps {
    dependentData: MemberToAdd;
    isBusy?: boolean;
    className?: string;
    onSubmit: (contactMethod: MembershipInvitationMethods) => void;
    onCancel: () => void;
    isInvitationModal?: boolean;
    isResend?: boolean;
}

const MembershipSendInvitation = ({
    dependentData,
    isBusy,
    className,
    isInvitationModal,
    isResend,
    onSubmit,
    onCancel
}: MembershipSendInvitationProps): ReactElement => {
    const { t } = useTranslation();
    const containerClasses = classNames('membership-send-invitation', className);
    const [contactMethod, setContactMethod] = useState<MembershipInvitationMethods | null>(null);
    const [contactError, setContactError] = useState<boolean>(false);
    const isLoadingSendInvite = useSelector(membershipIsLoadingSendInviteSelector);

    const handleRadio = (contactType: MembershipInvitationMethods) => {
        setContactError(false);
        setContactMethod(contactType);
    };

    const handleSubmit = () => {
        if (!contactMethod) {
            setContactError(true);
        } else {
            onSubmit(contactMethod);
        }
    };

    const actionButtons = () => {
        return (
            <>
                <Button
                    async
                    className="btn-send-invite"
                    isBusy={isLoadingSendInvite}
                    disabled={isLoadingSendInvite || !contactMethod}
                    type="button"
                    label={t('components.membership.sendInvitation.actions.submit')}
                    onClick={handleSubmit}
                />
                <Button
                    className="btn-cancel-invite"
                    type="button"
                    variant="text"
                    label={t('components.membership.sendInvitation.actions.cancel')}
                    onClick={onCancel}
                    disabled={isLoadingSendInvite}
                />
            </>
        );
    };

    const cardList = () => {
        return (
            <>
                {dependentData.email && (
                    <ContactInfoBox
                        id="add-dependent-send-invitation-as-email"
                        contactData={dependentData.email}
                        contactType="email"
                        isChecked={!!(contactMethod === 'Email')}
                        onChangeHandler={() => handleRadio('Email')}
                        hasError={contactError}
                    />
                )}
                {dependentData.email && dependentData.phone && <div className="card-list__other-option">Or</div>}
                {dependentData.phone && (
                    <ContactInfoBox
                        id="add-dependent-send-invitation-as-phone"
                        contactData={dependentData.phone}
                        contactType="phone"
                        isChecked={!!(contactMethod === 'Sms')}
                        onChangeHandler={() => handleRadio('Sms')}
                        hasError={contactError}
                    />
                )}
            </>
        );
    };

    if (isInvitationModal) {
        return (
            <div className={containerClasses}>
                <div className="membership-send-invitation__main-container">
                    <div className="membership-send-invitation__content-container">
                        <div className="membership-send-invitation__contact-options-container-modal">
                            <div className="options-text">
                                {isResend
                                    ? t('pages.profile.membership.manageGroupSection.modalResendInvitation.message')
                                    : t('pages.profile.membership.manageGroupSection.modalSendInvitation.message')}
                            </div>

                            <h3 className="membership-send-invitation__member-details__name-and-age">
                                {lowercaseAndCapitalize(`${dependentData.firstName} ${dependentData.lastName}`)}
                            </h3>

                            <div className="card-list">{cardList()}</div>

                            {isResend && (
                                <div className="membership-send-invitation__alert-messages__container alert-messages__container">
                                    <ToastBox icon="info" variant="info">
                                        {t(
                                            'pages.profile.membership.manageGroupSection.modalResendInvitation.messageAlert'
                                        )}
                                    </ToastBox>
                                </div>
                            )}

                            <div className="membership-send-invitation__actions-container">{actionButtons()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={containerClasses}>
            <div className="membership-send-invitation__main-container">
                <div className="membership-send-invitation__message-container">
                    <ToastBox variant="info">
                        {t('components.membership.sendInvitation.note', { name: dependentData.firstName })}
                    </ToastBox>
                </div>

                <div className="membership-send-invitation__content-container">
                    <MembershipMemberDetails
                        name={lowercaseAndCapitalize(`${dependentData.firstName} ${dependentData.lastName}`)}
                        age={dependentData.age}
                        accountType={dependentData.accountType}
                        dob={dependentData.dateOfBirth}
                    />

                    <div className="membership-send-invitation__contact-options-container">
                        <div className="options-text">
                            {t('components.membership.sendInvitation.chooseInviteOption')}
                        </div>

                        <div className="card-list__page">
                            {/* will be handled with BE ticket */}
                            {contactError && (
                                <div className="card-list__error-message">
                                    {t('components.membership.sendInvitation.contactSelectErrorMsg')}
                                </div>
                            )}
                            {cardList()}
                        </div>
                    </div>

                    <div className="membership-send-invitation__actions-container">{actionButtons()}</div>
                </div>
            </div>
        </div>
    );
};

export default MembershipSendInvitation;
