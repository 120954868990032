import classNames from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';

// Styles
import './membership-member-details.style.scss';

// Interface
export interface MembershipMemberDetailsProps {
    name: string;
    age: string | number;
    accountType: number;
    dob: string;
    className?: string;
}

const getAccountTypeString = (accountTypeIndex: number): string => {
    if (accountTypeIndex < 0 || accountTypeIndex > 3) {
        return '';
    }

    const accountTypes = ['Caregiver', 'Minor', 'Adult', 'Pet'];
    return accountTypes[accountTypeIndex];
};

const MembershipMemberDetails = ({ name, age, accountType, dob, className }: MembershipMemberDetailsProps) => {
    const { t } = useTranslation();
    const containerClasses = classNames('membership-member-details', className);

    const formatDate = (date: string) => {
        if (!date || date.split('/').length !== 3) return date;
        const [month, day, year] = date.split('/');

        const dayTreated = day.length === 1 ? `0${day}` : day;
        const monthTreated = month.length === 1 ? `0${month}` : month;

        return `${monthTreated}/${dayTreated}/${year}`;
    };

    return (
        <div className={containerClasses}>
            <h3 className="membership-member-details__name-and-age">
                {name}{' '}
                {age && (
                    <span className="age">
                        ({t('components.membership.confirmDetails.labels.age')}: {age})
                    </span>
                )}
            </h3>

            <ul className="membership-member-details__details-container">
                {accountType && (
                    <li className="membership-member-details__details-container__block">
                        <div className="label">{t('components.membership.confirmDetails.labels.accountType')}</div>
                        <div className="value">{getAccountTypeString(accountType)}</div>
                    </li>
                )}
                {dob && (
                    <li className="membership-member-details__details-container__block">
                        <div className="label">{t('components.membership.confirmDetails.labels.dateOfBirth')}</div>
                        <div className="value">{formatDate(dob)}</div>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default MembershipMemberDetails;
